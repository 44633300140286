import styled from 'styled-components';
import PricingCard from './components/PricingCard';

export const PracingContainer = styled.div`
  min-height: calc(100vh - 110px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 5%;
  flex-wrap: wrap;
  align-items: center;
`;

export const Header = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.color.offerSection.viewHeader};
  font-size: 60px;
  align-self: flex-start;
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1500px;
`;

export const SampleLessonCard = styled(PricingCard).attrs(({ theme }) => ({
  style: {
    backgroundColor: theme.color.pricingView.sampleLessonCardBgColor
  }
}))``;

export const GuaranteedPriceCard = styled(PricingCard).attrs(({ theme }) => ({
  style: {
    backgroundColor: theme.color.pricingView.guaranteedPriceCardBgColor
  }
}))``;

export const MultiPackagesCard = styled(PricingCard).attrs(({ theme }) => ({
  style: {
    backgroundColor: theme.color.pricingView.multiPackageCardBgColor
  }
}))``;

export const LessonTimeBox = styled.div`
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WaitBox = styled.div`
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClockImg = styled.img`
    width: 100px;
    height: 100px;
    margin-bottom: 0.5em;

    transform: translate3d(0, 0, 0);
  animation-name: shake;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes shake {
    2%, 18% {
      transform: translate3d(-1px, 0, 0);
  }

  4%, 16% {
    transform: translate3d(2px, 0, 0);
  }

  6%, 10%, 14% {
    transform: translate3d(-4px, 0, 0);
  }

  8%, 12% {
    transform: translate3d(4px, 0, 0);
  }
  
  18.1% {
      transform: translate3d(0px, 0, 0);
  }
}
`;