import styled from 'styled-components';
import Button from '../../../../components/Button';

export const OfferSection = styled.section<{ asView: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: ${({ asView }) => asView && '5%'};
`;

export const OfferDivider = styled.div`
  background-color: ${({ theme }) => theme.color.offerSection.headerBoxBg};
  flex: 1;
  min-height: 350px;
  max-height: 400px;
  display: flex;
  align-items: flex-end;
  padding: 1em;
  box-sizing: border-box;
`;

export const Header = styled.h1`
  margin-bottom: 0;
  color: ${({ theme }) => theme.color.offerSection.header};
  font-size: 60px;
  font-family: 'Poppins';
`;

export const ViewHeader = styled.h1<{ asView: boolean }>`
  margin: ${({ asView }) => asView && '0px'};
  margin-bottom: 0;
  color: ${({ theme }) => theme.color.offerSection.viewHeader};
  font-size: 60px;
  font-family: 'Poppins';
  box-sizing: border-box;
  border-bottom: 5px solid ${({ theme }) => theme.color.offerSection.lessonVerticalDivider};
`;

export const ViewHeaderBox = styled.div<{ asView: boolean }>`
  padding: ${({ asView }) => (asView ? '0px' : '1em 10vmin 0 10vmin')};
`;

export const OfferContent = styled.div<{ asView: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: ${({ asView }) => (asView ? '0px' : '0px 10vmin')};

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    padding: 10px;
  }`}
`;

export const LessonCategory = styled.div`
  width: 30%;
  min-width: 400px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: 5px solid ${({ theme }) => theme.color.offerSection.lessonVerticalDivider};
  box-sizing: border-box;
  position: relative;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.laptop} {
    min-width: 200px;
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    width: 100%;
    min-width: 100%;
    justify-content: center;
    border-right: none;
    margin-bottom: 1em;
  }`}
`;

export const IndividualLessonsBox = styled.div`
  display: flex;
  position: relative;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    flex-direction: column;
    padding: 1em 0px;
  }`}

  ::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.offerSection.lessonHorizontalDivider};
  }
`;

export const GroupLessonsBox = styled.div`
  display: flex;
  position: relative;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    flex-direction: column;
    padding: 1em 0px;
  }`}

  ::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.offerSection.lessonHorizontalDivider};
  }
`;
export const CourseLessonsBox = styled.div`
  display: flex;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    flex-direction: column;
    padding: 1em 0px;
  }`}
`;

export const LessonCategoryHeader = styled.h1`
  font-size: calc(5px + 5vmin);
  text-align: end;
  padding-right: 1em;
  color: ${({ theme }) => theme.color.offerSection.lessonTitle};

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.laptop} {
    font-size: calc(5px + 3vmin);
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    text-align: start;
    font-size: calc(5px + 5vmin);
  }`}
`;

export const CourseHeader = styled(LessonCategoryHeader)`
  color: ${({ theme }) => theme.color.offerSection.courseTitle};
`;

export const LessonExamples = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    width: 100%;
    justify-content: space-evenly;
  }`}
`;

export const LessonTileBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    height: 150px;
    width: 150px;
  }`}
`;

export const LessonTileImg = styled.div<{ basicSrc: string; hoverSrc: string }>`
  height: 100px;
  width: 100px;
  background-image: ${({ basicSrc }) => `url(${basicSrc})`};
  background-size: cover;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    background-image: ${({ hoverSrc }) => `url(${hoverSrc})`};
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
`;

export const LessonTileName = styled.h4`
  margin: 0;
  margin-top: 0.5em;
  color: ${({ theme }) => theme.color.offerSection.lessonName};
`;

export const MainButtonBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
`;

export const MainButton = styled(Button).attrs({
  style: {
    margin: '2em',
    padding: '10px 60px',
    fontSize: '20px',
    height: '100%',
    minHeight: '40px',
    fontWeight: '600'
  }
})``;

export const PreloadImg = styled.img`
  display: none;
`;
