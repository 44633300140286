import styled from 'styled-components';

export const ContactInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3em 1em 0;
  flex: 2;
  min-width: 250x;
`;

export const InfoHeader = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-size: 60px;
`;

export const InfoDesc = styled.p`
  margin-bottom: 2em;
  white-space: pre-line;
`;

export const LabelWithIconBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

export const PhoneInfoLabel = styled.p`
  color: ${({ theme }) => theme.color.contactView.phoneLabel};
  font-weight: 600;
`;

export const PhoneLabelWithIconBox = styled(LabelWithIconBox)`
  font-size: 24px;
  ${PhoneInfoLabel} {
    transition: transform 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const ContactInfoImg = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const ContactInfoLabel = styled.p``;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.contactView.divider};
  margin-top: 2em;
  opacity: 0.2;
`;

export const LocationHeader = styled.h2`
  margin: 20px 0;
`;

export const LocationLabel = styled.p`
  line-height: 1.8em;
  opacity: 0.7;
`;
