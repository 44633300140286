import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../routing/routes';
import { ButtonsBox, HomeSection, LargeView, MainBackgroundImg, MainButton, PhotoDesc, PhotoHeader, PhotoModalContainer, SmallMainImg, SmallView, SmallViewButton, SmallViewContent, SmallViewDesc, SmallViewHeader } from './style';

const MainHomeSection: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();


    return (
        <HomeSection>
            <LargeView>
                <MainBackgroundImg>
                    <PhotoModalContainer>
                        <PhotoHeader>{t('mainHomeSection.header')}</PhotoHeader>
                        <PhotoDesc>{t('mainHomeSection.desc')}</PhotoDesc>
                        <ButtonsBox>
                            <MainButton label={t('mainHomeSection.moreButton')} variant="primary" onClick={() => {
                                nav(`/${AppRoutes.Offer}`);
                            }} />
                            <MainButton label={t('mainHomeSection.contactButton')} variant="primary" onClick={() => {
                                nav(`/${AppRoutes.Contact}`);
                            }} />
                        </ButtonsBox>
                    </PhotoModalContainer>
                </MainBackgroundImg>
            </LargeView>
            <SmallView>
                <SmallMainImg
                    src="MobileMainPagePhoto.png"
                    alt="Zdjęcie przedstawiające nauczycielkę rozwiazującą zadanie szkolne z młodszą dziewczynką."
                />
                <SmallViewContent>
                    <SmallViewHeader>{t('mainHomeSection.header')}</SmallViewHeader>
                    <SmallViewDesc>{t('mainHomeSection.desc')}</SmallViewDesc>
                    <SmallViewButton
                        label={t('mainHomeSection.meetOurOffer')}
                        variant="primary"
                        onClick={() => {
                            nav(`/${AppRoutes.Offer}`);
                        }} />
                </SmallViewContent>
            </SmallView>
        </HomeSection>
    );
};

export default MainHomeSection;
