import React from 'react';
import { PrivacyPolicyContainer } from './style';


const PrivacyPolicyView: React.FC = () => {

    const renderPages = Array.from(Array(4).keys()).map(el => {
        const index = el + 1;
        return <img style={{
            width: '90%'
        }} key={index} src={`Polityka-prywatnosci_Wykorkowani_2022-${index}.jpg`} />;
    });

    return (
        <PrivacyPolicyContainer>
            {renderPages}
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicyView;