import styled from 'styled-components';
import Button from '../../../../components/Button';

export const HomeSection = styled.div`
  width: 100%;
  height: 100%;
`;

export const SmallView = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  padding: 0 5%;
  box-sizing: border-box;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: block;
  }`}
`;

export const LargeView = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  ${({ theme }) => `
   @media ${theme.minBreakpoints.laptop} {
    display: block;
  }`}
`;

export const MainBackgroundImg = styled.div`
  display: flex;
  padding: 100px;
  align-items: center;
  width: 100%;
  height: calc(100vh - 110px);
  background-image: url('MainPagePhoto.png');
  background-size: cover;
  box-sizing: border-box;
`;

export const PhotoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhotoHeader = styled.h1`
  color: ${({ theme }) => theme.color.homeSection.header};
  white-space: pre-line;
  font-size: 60px;
  line-height: 1.5em;
  margin: 0px;
`;

export const PhotoDesc = styled.h4`
  color: ${({ theme }) => theme.color.homeSection.desc};
  margin: 1em 0;
`;

export const ButtonsBox = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 1em;
  width: 380px;
  justify-content: space-between;
`;

export const MainButton = styled(Button).attrs({
  style: {
    fontSize: 15,
    fontWeight: 600,
    width: 180,
    padding: 0,
    boxSizing: 'border-box'
  }
})``;

export const SmallMainImg = styled.img`
  width: 70%;
`;

export const SmallViewContent = styled.div`
  padding: 0 5%;
  box-sizing: border-box;
`;

export const SmallViewHeader = styled.h1`
  margin-bottom: 0.3em;
`;

export const SmallViewDesc = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1.5em;
`;

export const SmallViewButton = styled(Button).attrs({
  style: {
    fontSize: 15,
    fontWeight: 600,
    width: 180,
    padding: 0,
    height: 50,
    boxSizing: 'border-box'
  }
})``;
