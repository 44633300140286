import React from "react";
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import ContactView from "../views/Contact";
import HomeView from "../views/Home";
import MainView from "../views/Main";
import OfferView from "../views/Offer";
import PricingView from "../views/Pricing";
import PrivacyPolicyView from "../views/PrivacyPolicy";
import ReviewsView from "../views/Reviews";
import TrainingView from "../views/Training";
import WrongPageView from "../views/WrongPage";
import { AppRoutes } from "./routes";
import ScrollToTop from "./ScrollToTop";

const AppRouting: React.FC = () =>
    useRoutes([
        {
            path: AppRoutes.Base, element: <MainView />, children: [
                { index: true, element: <Navigate to={AppRoutes.Home} /> },
                { path: AppRoutes.Home, element: <HomeView /> },
                { path: AppRoutes.Offer, element: <OfferView /> },
                { path: AppRoutes.Contact, element: <ContactView /> },
                { path: AppRoutes.Pracing, element: <PricingView /> },
                { path: AppRoutes.PrivacyPolicy, element: <PrivacyPolicyView /> },
                { path: AppRoutes.Training, element: <TrainingView /> },
                { path: AppRoutes.Opinions, element: <ReviewsView /> },


            ]
        },
        { path: AppRoutes.Default, element: <WrongPageView /> }
    ]);

const Navigation: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <AppRouting />
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default Navigation;