import styled from "styled-components";

export const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  font-size: 24px;
  position: relative;
  background-color: ${({ theme }) => theme.color.pricingView.package.tableHeaderBgColor};
  color: ${({ theme }) => theme.color.pricingView.package.tableHeader};
`;

export const InfoIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;