import styled from "styled-components";

export const PrivacyPolicyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    box-sizing: border-box;
    padding: 5%;
    padding-top: 8em;
`;