import styled from "styled-components";

export const OpinionCardShadow = styled.div`
  width: 300px;
  height: 300px;

  position: relative;
border-radius: 30%;
display: flex;
justify-content: center;
align-items: center;

    -webkit-box-shadow: 0px 0px 28px 0px rgba(66, 68, 90, 0.2);
    -moz-box-shadow: 0px 0px 28px 0px rgba(66, 68, 90, 0.2);
    box-shadow: 0px 0px 28px 0px rgba(66, 68, 90, 0.2);

    transition: transform 0.3s;

&:hover {
  transform: scale(1.03);
}
    
`;

export const OpinionCardBox = styled.div`
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  background-color: white;
 padding: 1em;
border-radius: 10px;
 display: flex;
 flex-direction: column;


`;

export const RatingAndTimeHeader = styled.div`
height: 40px;
min-height: 40px;
display: flex;
justify-content: space-between;
padding: 1em;

`;

export const RatingStars = styled.div`
min-width: 100px;
`;

export const TimeLabel = styled.p`
opacity: 0.4;
font-family: 'Poppins';

`;

export const ReviewBox = styled.div``;

export const ReviewContent = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const AuthorBox = styled.div`
`;

export const AuthorName = styled.p`
text-align: end;
padding: 1em;
font-weight: 600;
font-family: 'Poppins';

`

