import { color } from './color';

interface IVariantColor {
  color: string;
  bgColor: string;
}

interface IVariant {
  primary: Partial<IVariantColor>;
  orange: Partial<IVariantColor>;
}

interface IVariantStateStyle {
  default: IVariant;
  hover: IVariant;
  active: IVariant;
}

const variantOfColor: IVariantStateStyle = {
  default: {
    primary: {
      color: color.white,
      bgColor: color.lightBlue
    },
    orange: {
      color: color.white,
      bgColor: color.orange
    }
  },
  hover: {
    primary: {
      bgColor: color.blue
    },
    orange: {
      bgColor: color.darkOrange
    }
  },
  active: {
    primary: {
      bgColor: color.lightBlueActive
    },
    orange: {
      bgColor: color.darkOrangeActive
    }
  }
};

export type Variant = keyof IVariant;

export const buttonsAndLinks = {
  button: {
    primary: `
        background-color: ${variantOfColor.default.primary.bgColor};
        color: ${variantOfColor.default.primary.color};
        &:hover {
            background-color: ${variantOfColor.hover.primary.bgColor};
        }
        &:active {
            background-color: ${variantOfColor.active.primary.bgColor};
        }
        `,
    orange: `
        background-color: ${variantOfColor.default.orange.bgColor};
        color: ${variantOfColor.default.orange.color};
        &:hover {
            background-color: ${variantOfColor.hover.orange.bgColor};
        }
        &:active {
            background-color: ${variantOfColor.active.orange.bgColor};
        }
        `
  },
  link: {
    primary: `
        color: ${variantOfColor.default.primary};
        fill: ${variantOfColor.default.primary};
        &:hover {
            color: ${variantOfColor.hover.primary};
            fill: ${variantOfColor.hover.primary};
        }
        &:active {
            color: ${variantOfColor.active.primary};
            fill: ${variantOfColor.active.primary};
        }
        `
  }
};
