import React from 'react';
import ContactForm from './components/ContactForm';
import ContactInfo from './components/ContactInfo';
import { ContactContainer } from './style';

const ContactView: React.FC = () => {


    return (
        <ContactContainer>
            <ContactInfo />
            <ContactForm />
        </ContactContainer>
    );
};

export default ContactView;