import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const LargeMenuBox = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  max-height: 80px;
  height: 80px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.menu.background};
  padding-top: 10px;
  box-sizing: border-box;
  position: sticky;
  top: -1px;
  z-index: 1;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: none;
  }`}
`;

export const LargeMenuInsideBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  justify-content: space-evenly;
  width: 100%;
  max-width: 1000px;
`;

export const LargeMenuLogoContainer = styled.div`
  width: 100px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const LargeMenuLogoCircle = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  height: 140px;
  width: 140px;
  background-color: #fff;
  /* border: 5px solid #fff; */
`;

export const MockIcon = styled.img`
  height: 95%;
  width: 95%;
  background-color: #fff;
`;

export const LargeMenuNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.menu.label};
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins';
  text-decoration: none;
  /* flex: 1;
  max-width: 120px; */
  /* padding: 0 2em; */
  text-align: center;
  white-space: nowrap;

  &.active {
    color: ${({ theme }) => theme.color.menu.labelActive};
  }
`;

export const MockLink = styled.div``;
