import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainHomeSection from './sections/Main';
import Offer from './sections/Offer';
import OpinionsSection from './sections/Opinions';
import YourFuture from './sections/YourFuture';
import { HomePageContainer } from './style';

const HomeView: React.FC = () => {
    const nav = useNavigate();

    return <HomePageContainer>
        <div id="home">
            <MainHomeSection />
        </div>
        <div id="yourFuture">
            <YourFuture />
        </div>
        <div id="offer">
            <Offer />
        </div>
        <div id="opinions">
            <OpinionsSection />
        </div>
    </HomePageContainer>;
};

export default HomeView;
