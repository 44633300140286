export const color = {
  black: '#000',
  blackOpacity5: 'rgba(0, 0, 0, 0.5)',
  white: '#fff',
  blue: '#022D66',
  lightBlue: '#375CAD',
  lightBlue2: 'rgba(134, 170, 223, 0.1)',
  lightBlue3: 'blue',
  lightBlue4: 'rgba(134, 170, 223, 0.2)',
  lightBlue5: '#3B54C8',
  lightBlue6: '#5271FF',
  lightBlue7: '#99ACFF',
  lightBlue8: '#CDD6FF',
  lightBlue9: '#E3E8FF',
  lightBlue10: '#DAE1FF',
  lightBlueActive: '#35537B',
  orange: '#E9872F',
  lightOrange: '#ED7F31',
  darkOrange: '#C65E00',
  darkOrangeActive: '#DF6F0A',
  grey: '#C4C4C4',
  lightGrey: 'rgba(0, 0, 0, 0.2)',
  red: '#CC0001'
};
