import styled from "styled-components";


export const OpinionSliderBox = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
`;

export const OpinionSliderItemsContainer = styled.div`
    justify-content: center;
    display: flex;
    position: relative;
    height: 360px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    max-width: 360px;
    @media (min-width: 840px) { // 360* + 120
    max-width: 720px;
  }

  @media (min-width: 1200px) { // 360* + 120
    max-width: 1080px;
  }

  @media (min-width: 1560px) { // 360* + 120
    max-width: 1440px;
  }

`;

export const OpinionSliderItems = styled.div`
    display: flex;
    box-sizing: border-box;
    max-height: 320px;
    position: absolute;
    left: 0px;
    transition: all 0.3s; 
`;
export const ButtonContainer = styled.div`
min-width: 20px;
z-index: 1;
flex: 1;
justify-content: center;
align-items: center;
text-align: center;
`;
export const PrevButton = styled.img`
    width: 30px;
    height: 30px;
    cursor: pointer;

    ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    width: 20px;
    height: 20px;
  }`}

`;
export const NextButton = styled.img`
    width: 30px;
    height: 30px;
    cursor: pointer;

    ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    width: 20px;
    height: 20px;
  }`}

`;

export const ItemContainer = styled.div<any>`
        padding: 30px;
`;