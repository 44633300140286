import React from 'react';
import { useTranslation } from 'react-i18next';
import PricingTables from './components/PricingTables';
import { CardsContainer, Header, PracingContainer, SampleLessonCard, GuaranteedPriceCard, MultiPackagesCard, LessonTimeBox, ClockImg, WaitBox } from './style';
import sampleLessonIcon from '../../images/graduation-hat.png';
import guaranteedPriceIcon from '../../images/medal.png';
import multiPackageIcon from '../../images/pencils.png';
import chronometerIcon from '../../images/chronometer.png';


const PricingView: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PracingContainer>
            <Header>{t('pricingView.mainHeader')}</Header>
            <CardsContainer>
                <SampleLessonCard
                    icon={sampleLessonIcon}
                    title={t('pricingView.sampleLessonCard.title')}
                    desc={t('pricingView.sampleLessonCard.desc')}
                />
                <MultiPackagesCard
                    icon={multiPackageIcon}
                    title={t('pricingView.multiPackageCard.title')}
                    desc={t('pricingView.multiPackageCard.desc')}
                />
                <GuaranteedPriceCard
                    icon={guaranteedPriceIcon}
                    title={t('pricingView.guaranteedPriceCard.title')}
                    desc={t('pricingView.guaranteedPriceCard.desc')}
                />
            </CardsContainer>
            <LessonTimeBox>
                <ClockImg src={chronometerIcon} alt="Animacja drgającego zegarka" />
                <h1 style={{ textAlign: 'center', margin: 0, fontSize: 55 }}>U nas każda lekcja trwa 60 minut.</h1>
            </LessonTimeBox>
            <PricingTables />
        </PracingContainer>
    );
};

export default PricingView;