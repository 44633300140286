import styled from 'styled-components';
import Button from '../../../../components/Button';

export const CardInner = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  -moz-transition: 1s;
  -webkit-transition: 1s;
  transition: 1s;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  border-radius: 1em;
`;

export const CardBox = styled.div`
  display: flex;
  flex: 1;
  min-width: 300px;
  max-width: 380px;
  height: 500px;
  margin: 1em;
  background-color: transparent;
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
  perspective: 1000px;

  &:hover ${CardInner} {
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
`;

export const FrontCard = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-bottom: 1em;
  flex-direction: column;

  position: absolute;

  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  box-sizing: border-box;
  border-radius: 1em;
`;

export const CardContent = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const BackCardContent = styled(CardContent)`
  justify-content: center;
`;

export const CardIcon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 1em;
`;
export const CardTitle = styled.h2`
  color: ${({ theme }) => theme.color.pricingView.cardsTitle};
  font-size: 38px;
  max-width: 300px;
  font-weight: 600;
  text-align: center;
`;

export const CardButton = styled(Button).attrs({
  style: {
    fontWeight: '600',
    fontSize: '20px',
    width: '120px'
  }
})``;

export const BackCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  -moz-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 3em;
  box-sizing: border-box;
  text-align: center;
  border-radius: 1em;

  &:hover {
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
`;

export const CardDesc = styled.p`
  color: ${({ theme }) => theme.color.pricingView.cardsDesc};
  font-size: 18px;
  line-height: 1.5em;
  white-space: pre-line;
`;

export const ButtomFlipIcon = styled.img`
  width: 50px;
  height: 50px;
`;
