import styled from 'styled-components';
import Button from '../../../../components/Button';
import ContactInput from '../../../../components/ContactInput';
import ContactTextarea from '../../../../components/ContactTextarea';

export const FormContactBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 3;
  min-width: 600px;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    min-width: 400px;
    padding: 1rem 0;
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.mobile} {
    min-width: 330px;
    padding: 1rem 0;
  }`}
`;

export const HeaderAndDescBox = styled.div`
  text-align: end;
  margin-bottom: 1em;
`;
export const ContactHeader = styled.h2``;
export const LeaveLabel = styled.p``;
export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.contactView.formBg};
  padding: 1em 3em;
  position: relative;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    padding: 1em;
  }`}

  ::before {
    content: '';
    margin: 1em 2em;
    background: url('contactmen.png');
    background-size: cover;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 230px;
    height: 230px;

    ${({ theme }) => `
   @media ${theme.maxBreakpoints.mobile} {
    display: none;
  }`}
  }
`;
export const FormHeader = styled.h2``;
export const InputsContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.mobile} {
    width: 100%;
  }`}
`;
export const NameInput = styled(ContactInput).attrs({
  style: {
    margin: '0.5em 0',
    width: '100%'
  }
})``;
export const Emailinput = styled(ContactInput).attrs({
  style: {
    margin: '0.5em 0',
    width: '100%'
  }
})``;
export const PhoneImput = styled(ContactInput).attrs({
  style: {
    margin: '0.5em 0',
    width: '100%'
  }
})``;
export const PrefferedHoursBox = styled.div`
  display: flex;
  align-items: center;
`;
export const PrefferedHoursLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
`;
export const PrefferedHoursInput = styled(ContactInput).attrs({
  style: {
    margin: '0.5em',
    width: '101px'
  }
})``;
export const MoreInfoBox = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    align-items: start;
    flex-direction: column;
  }`}
`;
export const MoreInfoLabel = styled.p`
  min-width: 140px;
  font-weight: 600;
  font-size: 14px;
`;
export const MoreInfoFieldContainer = styled.div`
  margin: 0.5em 0 1em 0.5em;
  width: 100%;
  display: flex;
  box-sizing: border-box;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    margin: 0.5em 0 1em 0;
  }`}
`;

export const MoreInfoField = styled(ContactTextarea).attrs({
  style: {
    width: '100%'
  }
})``;
export const SubmitButton = styled<any>(Button).attrs<any>({
  style: {
    fontSize: 15,
    fontWeight: 600,
    width: 150,
    padding: 0,
    boxSizing: 'border-box',
    alignSelf: 'flex-end'
  }
})``;

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const PrefferedHoursIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const MoreInfoIcon = styled(PrefferedHoursIcon)``;
