import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactInfoBox, InfoHeader, InfoDesc, LabelWithIconBox, ContactInfoImg, ContactInfoLabel, Divider, LocationHeader, LocationLabel, PhoneInfoLabel, PhoneLabelWithIconBox } from './style';
import EmailIcon from '../../../../images/envelope.png';
import PhoneIcon from '../../../../images/telephone-handle-silhouette.png';
import LocationIcon from '../../../../images/map-marker.png';

const ContactInfo: React.FC = () => {
    const { t } = useTranslation();

    return (
        <ContactInfoBox>
            <InfoHeader>{t('contactView.info.header')}</InfoHeader>
            <InfoDesc>{t('contactView.info.desc')}</InfoDesc>
            <LabelWithIconBox>
                <ContactInfoImg src={EmailIcon} alt="Ikona poczty" />
                <ContactInfoLabel>{t('contact.email')}</ContactInfoLabel>
            </LabelWithIconBox>
            <PhoneLabelWithIconBox>
                <ContactInfoImg src={PhoneIcon} alt="Ikona telefonu" />
                <PhoneInfoLabel>{t('contact.phone_number')}</PhoneInfoLabel>
            </PhoneLabelWithIconBox>
            <LabelWithIconBox>
                <ContactInfoImg src={LocationIcon} alt="Ikona lokalizacji" />
                <ContactInfoLabel>{t('contactView.info.adressLabel')}</ContactInfoLabel>
            </LabelWithIconBox>
            <Divider />
            <LocationHeader>{t('contactView.info.locationHeader')}</LocationHeader>
            <LocationLabel>{t('company.openLocation')}</LocationLabel>
            <LocationLabel>{t('company.street')}</LocationLabel>
            <LocationLabel>{`${t('company.city')}, ${t('company.zip')}`}</LocationLabel>
            <LocationLabel>{`NIP: ${t('company.nip')}`}</LocationLabel>
            <LocationLabel>{`Nr konta (${t('company.bankName')}): ${t('company.nrAccount')}`}</LocationLabel>
        </ContactInfoBox>
    );
};

export default ContactInfo;