import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SmallFooterContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.footerMobile.background};
  display: none;
  padding: 1em;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  
  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: flex;
  }`}
`;

export const SmallFooterContentBox = styled.div`
  width: 100%;
  max-width: 500px; 
`;

export const InfoPartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
`;
export const SmallFooterInfoHeader = styled.h4`
  margin: 0.5em 0;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.footerMobile.header};
`;

export const SmallFooterInfoLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.footerMobile.label};
  opacity: 1;
`;

export const SmallFooterInfoPart = styled.div`
  padding: 10px;
`;

export const SmallFooterInfoPartDownSection = styled.div`
  margin-top: 30px;
`;

export const SmallFooterListOfCompanyInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  & li {
    margin: 10px 0;
    font-size: 12px;
    color: ${({ theme }) => theme.color.footerMobile.label};
    opacity: 1;
  }
`;

export const SmallFooterContactLabelBox = styled.div`
  display: flex;
  align-items: center;

  & *:first-child {
    margin-right: 1em;
  }
`;

export const SmallFooterInfoIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const SmallFooterInfoWithIconBox = styled.div`
  display: flex;
  align-items: center;
`;

export const CompanyNameHeaderBox = styled.div`
display: flex;
align-items: center;
`;

export const CompanyNameHeader = styled.h1``;

export const CompanyNameHeaderIcon = styled.img`
width: 50px;
height: 50px;
`;

export const SmallFooterNavLinksBox = styled.div`
  margin: 2em 0px;
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const SmallFooterNavLink = styled<any>(NavLink)`
  color: ${({ theme }) => theme.color.footerMobile.link};
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins';
  text-decoration: none;
  padding: 0.5em;
  text-align: center;
  white-space: nowrap;
  text-align: start;
`;
export const SocialMediaIcon = styled.img`
  padding-top: 30px;
  margin-right: 19px;
  height: 27px;
  width: 27px;
  cursor: pointer;
`;
