import styled from 'styled-components';

export const StyledInput = styled.input`
  padding: 0.5em 0;
  outline: none;
  border: 0;
  font-size: 16px;
  height: 34px;

  background-color: transparent;
  box-sizing: border-box;
  width: 100%;

  &::placeholder {
    font-size: 14px;
  }
`;

export const InputBox = styled.div<{ isError: boolean }>`
  position: relative;

  &::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    left: 0px;
    bottom: 0px;
    content: ' ';
    position: absolute;
    right: 0px;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  &::after {
    border-bottom: 2px solid rgb(25, 118, 210);
    left: 0px;
    bottom: 0px;
    content: '';
    position: absolute;
    right: 0px;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  &:hover {
    &::before {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
  }

  &:focus-within {
    &::after {
      transform: scaleX(1) translateX(0px);
    }
  }

  ${({ isError }) =>
    isError &&
    `
      &::before {
        border-bottom: 2px solid red;
      }

      &::after {
        border-bottom: 2px solid red;
      }
  `}
`;

export const ErrorMsg = styled.span`
  position: absolute;
  top: 10px;
  right: 0px;
  color: red;
  font-size: 12px;
`;
