import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPartContainer, LargeFooterBox, LargeFooterListOfCompanyInfo, LargeFooterInfoHeader, LargeFooterInfoLabel, LargeFooterInfoPart, LargeFooterInfoPartDownSection, LargeFooterContactLabelBox, LargeFooterLogoCircle, SocialMediaIcon } from './style';
import logo from '../../images/logoCircle.png';
import { AppRoutes } from '../../routing/routes';
import { useNavigate } from 'react-router-dom';
import facebookIcon from '../../images/facebook-icon.png';
import instagramIcon from '../../images/instagram-icon.png';
import { handleClickFacebook, handleClickInstagram } from '../../helpers/socialMediaLinks';

const LargeFooter: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();


    return <LargeFooterBox>
        <LargeFooterInfoPart>
            <LargeFooterLogoCircle src={logo} alt="Logo wykorkowani" title="Logo wykorkowani" />
        </LargeFooterInfoPart>
        <InfoPartContainer>
            <LargeFooterInfoPart>
                <LargeFooterInfoHeader>{t('label.company_data')}</LargeFooterInfoHeader>
                <LargeFooterListOfCompanyInfo>
                    <li>{t('company.openLocation')}</li>
                    <li>{t('company.street')}</li>
                    <li>{`${t('company.city')}, ${t('company.zip')}`}</li>
                    <li>{`NIP ${t('company.nip')}`}</li>
                </LargeFooterListOfCompanyInfo>
            </LargeFooterInfoPart>

            <LargeFooterInfoPart>
                <LargeFooterInfoHeader>{t('label.opening_hours')}</LargeFooterInfoHeader>
                <LargeFooterInfoLabel>{t('contact.opening_hours')}</LargeFooterInfoLabel>
                <LargeFooterInfoPartDownSection>

                    <LargeFooterInfoHeader onClick={() => nav(AppRoutes.PrivacyPolicy)}>{t('label.privacy_policy')}</LargeFooterInfoHeader>

                </LargeFooterInfoPartDownSection>
            </LargeFooterInfoPart>

            <LargeFooterInfoPart>
                <LargeFooterContactLabelBox>
                    <LargeFooterInfoHeader>{t('label.call')}</LargeFooterInfoHeader>
                    <LargeFooterInfoLabel>{t('contact.phone_number')}</LargeFooterInfoLabel>
                </LargeFooterContactLabelBox>
                <LargeFooterContactLabelBox>
                    <LargeFooterInfoHeader>{t('label.write_to_us')}</LargeFooterInfoHeader>
                    <LargeFooterInfoLabel>{t('contact.email')}</LargeFooterInfoLabel>
                </LargeFooterContactLabelBox>
                <LargeFooterContactLabelBox>
                    <SocialMediaIcon src={instagramIcon} alt="Ikona Instagram"
                        onClick={handleClickInstagram} />
                    <SocialMediaIcon src={facebookIcon} alt="Ikona Facebook"
                        onClick={handleClickFacebook} />
                </LargeFooterContactLabelBox>

            </LargeFooterInfoPart>
        </InfoPartContainer>


    </LargeFooterBox>;
};

export default LargeFooter;
