import React, { useMemo } from 'react';
import { RaitingStarsContainer, StarBox } from './style';
import { ReactComponent as Star } from '../../images/star.svg';

interface IStarRaitingProps {
  raiting: number;
  rate?: (rating: number) => void;
  size?: number;
}

const StarRaiting: React.FC<IStarRaitingProps> = ({
  raiting,
  size = 35,
  rate,
}) => {
  const stars = useMemo(() => {
    return [...Array(5).keys()].map(el => {
      const index = el + 1;
      const color = index <= raiting ? 'darkorange' : 'grey';
      return (
        <Star key={index} width={size} height={size} fill={color} />
      );
    });
  }, [raiting, rate, size]);

  return <RaitingStarsContainer>{stars}</RaitingStarsContainer>;
};

export default StarRaiting;
