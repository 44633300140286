import styled from 'styled-components';

export const SocialMediaBox = styled.div`
  display: flex;
`;

export const ContactHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  height: 30px;
  box-sizing: border-box;
  position: relative;
  background-color: ${({ theme }) => theme.color.header.background};
  color: ${({ theme }) => theme.color.header.label};

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: none;
  }`}
`;

export const ContactHeaderLabelsBox = styled.div`
  display: flex;
`;

export const ContactHeaderLabelbox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:not(:first-child) {
    ::before {
      content: '';
      height: 100%;
      width: 1px;
      background-color: ${({ theme }) => theme.color.header.divider};
    }
  }
`;

export const ContactHeaderLabel = styled.p`
  font-size: 10px;
  cursor: pointer;
  margin-right: 5px;
`;

export const ContactHeaderLabelIcon = styled.img`
  margin: 0 5px 0 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
export const SocialMediaIcon = styled.img`
  margin: 0 5px 0 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
