import { buttonsAndLinks } from './buttonsTheme';
import { color } from './color';
import { IThemeCategory } from './interfaces';

export interface Theme {
  theme: IThemeCategory;
}

export const integerBreakpointSize = {
  mobile: 320,
  tablet: 481,
  laptop: 769,
  desktop: 1025,
  monitor: 1440
};

export const pixelBreakpointSize = {
  mobile: `${integerBreakpointSize.mobile}px`,
  tablet: `${integerBreakpointSize.tablet}px`,
  laptop: `${integerBreakpointSize.laptop}px`,
  desktop: `${integerBreakpointSize.desktop}px`,
  monitor: `${integerBreakpointSize.monitor}px`
};

export const homeSectionHeight = 'calc(100vh - 50px)';

export const theme: IThemeCategory = {
  color: {
    ...color,
    header: {
      background: color.lightBlue,
      label: color.white,
      divider: color.white
    },
    menu: {
      background: color.blue,
      label: color.white,
      labelActive: color.orange,
      logoOutline: color.white,
      backgroundLogo: color.white
    },
    homeSection: {
      header: color.lightBlue,
      desc: color.black
    },
    yourFutureSection: {
      header: color.blue,
      headerBoxBg: color.white,
      tileBg: color.lightBlue2,
      tileHeader: color.black,
      tileDesc: color.black,
      diffSingleWord: color.lightBlue
    },
    offerSection: {
      header: color.white,
      viewHeader: color.black,
      headerBoxBg: color.lightBlue,
      lessonVerticalDivider: color.lightBlue,
      lessonHorizontalDivider: color.lightGrey,
      lessonTitle: color.black,
      courseTitle: color.orange,
      lessonName: color.lightBlue
    },
    footer: {
      background: color.lightBlue,
      header: color.white,
      label: color.white,
      logoOutline: color.white,
      backgroundLogo: color.white,
      divider: color.white
    },
    footerMobile: {
      background: color.white,
      header: color.black,
      label: color.black,
      link: color.blue
    },
    contactView: {
      headers: color.black,
      desc: color.black,
      phoneLabel: color.orange,
      divider: color.black,
      formBg: color.lightBlue4
    },
    training: {
      header: color.black,
      sectionHeader: color.lightBlue5,
      sectionDesc: color.black,
      sectionItemLabel: color.white
    },
    pricingView: {
      mainHeader: color.black,
      cardsTitle: color.white,
      cardsDesc: color.white,
      sampleLessonCardBgColor: color.lightBlue5,
      guaranteedPriceCardBgColor: color.blue,
      multiPackageCardBgColor: color.lightBlue6,
      dividers: color.blackOpacity5,
      singleLesson: {
        header: color.black,
        price: color.orange,
        bgColor: color.lightBlue9
      },
      package: {
        header: color.black,
        tableHeader: color.white,
        tableHeaderBgColor: color.blue,
        timeOptionLabel: color.black,
        timeOptionBgColor: color.lightBlue7,
        firstPriceOptionBgColor: color.lightBlue8,
        secondPriceOptionBgColor: color.lightBlue9,
        thirdPriceOptionBgColor: color.lightBlue10,
        timeToUseBgColor: color.lightBlue9,
        timeToUseLabel: color.black,
        timeLabel: color.black,
        pricePerHourLabel: color.black,
        packagePriceLabel: color.black,
        oldPackagePrice: color.red,
        packagePrice: color.black
      },
      moreInfo: {
        bgColor: color.lightBlue8,
        color: color.black
      }
    }
  },
  ...buttonsAndLinks,
  minBreakpoints: {
    mobile: `(min-width: ${pixelBreakpointSize.mobile})`,
    tablet: `(min-width: ${pixelBreakpointSize.tablet})`,
    laptop: `(min-width: ${pixelBreakpointSize.laptop})`,
    desktop: `(min-width: ${pixelBreakpointSize.desktop})`,
    monitor: `(min-width: ${pixelBreakpointSize.monitor})`
  },
  maxBreakpoints: {
    mobile: `(max-width: ${pixelBreakpointSize.tablet})`,
    tablet: `(max-width: ${pixelBreakpointSize.laptop})`,
    laptop: `(max-width: ${pixelBreakpointSize.desktop})`,
    desktop: `(max-width: ${pixelBreakpointSize.monitor})`
  }
};
