import React from 'react';
import { InfoIcon, StyledTableHeader } from './style';
import questionMark from '../../../../images/question-mark.png';

interface ITableHeaderProps {
    refToElement: any;
    children: string;
}

const TableHeader: React.FC<ITableHeaderProps> = ({ children, refToElement }) => {
    const handleInfoIconClick = () => {
        const navHeaderheight = 80;
        const centerOfInfo = 100;
        const setPosition = window.scrollY - navHeaderheight - centerOfInfo;
        const positionTop = refToElement.current.getBoundingClientRect().top + setPosition;
        window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: positionTop
        });
    };
    return (
        <StyledTableHeader>
            {children}
            <InfoIcon src={questionMark} alt="Ikona pytajnika" onClick={handleInfoIconClick} />
        </StyledTableHeader>
    );
};

export default TableHeader;