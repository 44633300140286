import React, { useEffect, useState } from 'react';
import OpinionCard from '../../components/OpinionCard';


import { OpinionCardsBox, ReviewsContainer, ViewHeader } from './style';

const ReviewsView: React.FC = () => {
    const headertext = 'Poznaj opinie\nnaszych kursantów';
    const [opinions, setOpinions] = useState<any>([]);

    useEffect(() => {
        fetch(`/opinions.json?t=${new Date().getTime()}`)
            .then((res) => res.json())
            .then((data) => {
                setOpinions(data.reverse());
            });
    }, []);

    return <ReviewsContainer>
        <ViewHeader>{headertext}</ViewHeader>
        <OpinionCardsBox>
            {opinions.map((opinion: any) => (
                <OpinionCard
                    key={opinion.name + opinion.review}
                    review={opinion.review}
                    rating={opinion.rating}
                    name={opinion.name}
                    time={opinion.time}
                />
            ))}

        </OpinionCardsBox>
    </ReviewsContainer>;
};

export default ReviewsView;

