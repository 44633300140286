import styled from 'styled-components';

export const PricingTablesContainer = styled.div`
  padding: 3%;
  padding-bottom: 0;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    padding: 0;
  }`}
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const LeftDivider = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.pricingView.dividers};
  height: 1px;
`;

export const RightDevider = styled(LeftDivider)``;

export const THeader = styled.h1`
  padding: 0 1em;
  font-size: 40px;
  color: ${({ theme }) => theme.color.pricingView.singleLesson.header};

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    padding: 0 0.5em;
    font-size: 25px;
  }`}
`;

export const OneLessonBox = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.color.pricingView.singleLesson.bgColor};
`;

export const OneLessonBoxStudent = styled(OneLessonBox)`
  margin-top: 2em;
  padding: 0.5em;
  box-sizing: border-box;
  position: relative;
`

export const OneLessonHeaderStudent = styled.h3`
  margin: 0px;
`


export const OneLessonPrice = styled.h1`
  padding: 0.5em;
  margin: 0;
  color: ${({ theme }) => theme.color.pricingView.singleLesson.price};
`;

export const OneLessonPriceStudent = styled(OneLessonPrice)`
  /* margin-top: 2em; */
  padding: 0 0.5em;
`

export const OneLessonHeaderStudentCup = styled.img`
  position: absolute;
  width: 100px;
  top: -50px;
  right: -30px;
  transform: rotateZ(5deg);

`

export const OneLessonHeaderStudentElCup = styled.span`
  position: absolute;
  width: 50px;
    height: 50px;
    border-radius: 26px;
    display: block;
    background-color: #E3E8FF;
    top: 1px;
    right: 0px;
`

export const PackagesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TableBox = styled.div`
  flex: 1;
  margin: 1em 0 0 0;
`;

export const SystematicTableBox = styled(TableBox)`
  /* margin-right: 3em;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.laptop} {
    margin-right: 1em;
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    margin-right: 0px;
  }`} */
`;

export const ElastiicTableBox = styled(TableBox)`
  margin-left: 3em;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.laptop} {
    margin-left: 1em;
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    margin-left: 0px;
  }`}
`;
