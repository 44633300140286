import React from 'react';
import { CardBox, BackCardContent, CardContent, CardTitle, CardIcon, CardInner, BackCard, FrontCard, CardDesc, ButtomFlipIcon } from './style';
import flipArrowIcon from '../../../../images/repeat.png';

interface IPricingCard {
    icon: any,
    title: string,
    desc: string,
    style?: any,
}

const PricingCard: React.FC<IPricingCard> = ({
    icon,
    title,
    desc,
    style
}) => {


    return (
        <CardBox>
            <CardInner>
                <FrontCard style={style}>
                    <CardContent>
                        <CardIcon src={icon} />
                        <CardTitle>{title}</CardTitle>
                    </CardContent>
                    <ButtomFlipIcon src={flipArrowIcon} />

                </FrontCard>
                <BackCard style={style}>
                    <BackCardContent>
                        <CardDesc dangerouslySetInnerHTML={{
                            __html: desc
                        }} />
                    </BackCardContent>
                    <ButtomFlipIcon src={flipArrowIcon} />

                </BackCard>

            </CardInner>
        </CardBox>
    );
};

export default PricingCard;