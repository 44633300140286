import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import './App.css';
import Navigation from './routing/Navigation';


const App: React.FC = () => {
  return (
    <div className="wykorkowani-app">
      <ThemeProvider theme={theme}>
        <Navigation />
      </ThemeProvider>
    </div>
  );
};

export default App;
