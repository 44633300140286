import React from 'react';
import ElasticTable from '../ElasticTable';
import SystematicTable from '../SystematicTable';
import { ElastiicTableBox, LeftDivider, OneLessonBox, OneLessonBoxStudent, OneLessonHeaderStudent, OneLessonHeaderStudentCup, OneLessonHeaderStudentElCup, OneLessonPrice, OneLessonPriceStudent, PackagesBox, PricingTablesContainer, RightDevider, SystematicTableBox, TableBox, TableHeaderContainer, THeader } from './style';

const TableHeader: React.FC<any> = ({ children }) => {

    return (
        <TableHeaderContainer>
            <LeftDivider />
            <THeader>{children}</THeader>
            <RightDevider />
        </TableHeaderContainer>
    );
};

const PricingTables: React.FC = () => {

    return (
        <PricingTablesContainer>
            <TableHeader>Lekcja jednorazowa</TableHeader>
            <OneLessonBox>
                <OneLessonPrice>120 zł</OneLessonPrice>
            </OneLessonBox>
            <OneLessonBoxStudent>
                <OneLessonHeaderStudent>Poziom akademicki</OneLessonHeaderStudent>
                <OneLessonPriceStudent>150 zł/h</OneLessonPriceStudent>
                <OneLessonHeaderStudentCup src='graduation_cap.png' />
                <OneLessonHeaderStudentElCup></OneLessonHeaderStudentElCup>
            </OneLessonBoxStudent>
            <TableHeader>Pakiety</TableHeader>
            <PackagesBox>
                <SystematicTableBox><SystematicTable /></SystematicTableBox>
                {/* <ElastiicTableBox><ElasticTable /></ElastiicTableBox> */}
            </PackagesBox>
        </PricingTablesContainer>
    );
};

export default PricingTables;