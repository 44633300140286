import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactHeaderBox, ContactHeaderLabel, ContactHeaderLabelbox, ContactHeaderLabelIcon, ContactHeaderLabelsBox, SocialMediaBox, SocialMediaIcon } from './style';
import phoneIcon from '../../images/phone-call.png';
import emailIcon from '../../images/email.png';
import locationIcon from '../../images/location-mark.png';
import timeIcon from '../../images/timeIcon.png';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../routing/routes';
import facebookIcon from '../../images/facebook-icon.png';
import instagramIcon from '../../images/instagram-icon.png';
import { handleClickFacebook, handleClickInstagram } from '../../helpers/socialMediaLinks';




const ContactHeader: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();



    return <ContactHeaderBox>
        <SocialMediaBox>
            <ContactHeaderLabel onClick={() => nav(AppRoutes.PrivacyPolicy)}>{t('label.privacy_policy')}</ContactHeaderLabel>
            <ContactHeaderLabelbox>
                <ContactHeaderLabelIcon src={facebookIcon} alt="Ikona Facebook"
                    onClick={handleClickFacebook} />
                <SocialMediaIcon src={instagramIcon} alt="Ikona Instagram"
                    onClick={handleClickInstagram} />
            </ContactHeaderLabelbox>
        </SocialMediaBox>
        <ContactHeaderLabelsBox>
            <ContactHeaderLabelbox>
                <ContactHeaderLabelIcon src={locationIcon} alt="Ikona lokalizacja" />
                <ContactHeaderLabel>{t('contact.location')}</ContactHeaderLabel>
            </ContactHeaderLabelbox>

            <ContactHeaderLabelbox>
                <ContactHeaderLabelIcon src={phoneIcon} alt="Ikona telefon" />
                <ContactHeaderLabel>{t('contact.phone_number')}</ContactHeaderLabel>
            </ContactHeaderLabelbox>

            <ContactHeaderLabelbox>
                <ContactHeaderLabelIcon src={timeIcon} alt="Ikona zegarek" />
                <ContactHeaderLabel>{t('contact.opening_hours')}</ContactHeaderLabel>
            </ContactHeaderLabelbox>

            <ContactHeaderLabelbox>
                <ContactHeaderLabelIcon src={emailIcon} alt="Ikona poczta" />
                <ContactHeaderLabel>{t('contact.email')}</ContactHeaderLabel>
            </ContactHeaderLabelbox>
        </ContactHeaderLabelsBox>
    </ContactHeaderBox>;
};

export default ContactHeader;
