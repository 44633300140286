import styled from 'styled-components';

export const LargeFooterBox = styled.footer`
  /* min-height: 200px;
  height: 200px; */
  width: 100%;
  background-color: ${({ theme }) => theme.color.footer.background};
  padding: 10px 6em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto; //REMOVE

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: none;
  }`}
`;

export const InfoPartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
`;
export const LargeFooterInfoHeader = styled.h4`
  margin: 0.5em 0;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.footer.header};
`;

export const LargeFooterInfoLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.footer.label};
  opacity: 0.5;
`;

export const LargeFooterInfoPart = styled.div`
  padding: 10px;
  height: 150px;
`;

export const LargeFooterInfoPartDownSection = styled.div`
  margin-top: 30px;
`;

export const LargeFooterListOfCompanyInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  & li {
    margin: 10px 0;
    font-size: 12px;
    color: ${({ theme }) => theme.color.footer.label};
    opacity: 0.5;
  }
`;

export const LargeFooterContactLabelBox = styled.div`
  display: flex;
  align-items: center;

  & *:first-child {
    margin-right: 1em;
  }
`;

export const LargeFooterLogoCircle = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  border: 5px solid ${({ theme }) => theme.color.footer.logoOutline};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.footer.backgroundLogo};
`;
export const SocialMediaIcon = styled.img`
  padding-top: 13px;
  height: 22px;
  width: 22px;
  cursor: pointer;
`;
