import styled from "styled-components";

export const StickyHeadersContainer = styled.div`
    /* position: fixed;
    top: 0;  */
    width: 100%;
`;

export const ContentContainer = styled.div`
    /* padding-top: 110px; */
`;