import React from 'react';
import { HeaderText, InfoContainer, DescText, FooterText, InfoIcon } from './style';
import questionMark from '../../../../images/question-mark.png';

interface IPricingInfoProps {
    header: string,
    desc: string,
    footer: string;
}

const PricingInfo = React.forwardRef<HTMLElement, IPricingInfoProps>(({ header, desc, footer }, ref) => {

    return (
        <InfoContainer ref={ref}>
            <InfoIcon src={questionMark} alt="Ikona - pytajnik" />
            <HeaderText dangerouslySetInnerHTML={{
                __html: header
            }} />
            {/* <DescText dangerouslySetInnerHTML={{
                __html: desc
            }} />
            <FooterText dangerouslySetInnerHTML={{
                __html: footer
            }} /> */}
        </InfoContainer>
    );
});

PricingInfo.displayName = "PricingInfo";

export default PricingInfo;