export enum AppRoutes {
  Default = '*',
  Base = '/',
  Home = 'home',
  About = 'about-us',
  Contact = 'contact',
  Offer = 'offer',
  Pracing = 'pricing',
  Opinions = 'opinions',
  PrivacyPolicy = 'privacy-policy',
  Training = 'training'
}
