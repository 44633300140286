import React from 'react';
import StarRaiting from '../StarRaiting';
import { OpinionCardBox, OpinionCardShadow, RatingAndTimeHeader, ReviewContent, AuthorName, RatingStars, TimeLabel, ReviewBox } from './style';

interface OpinionCardProps {
    name: string,
    review: string,
    rating: number,
    time: string,
}
const OpinionCard: React.FC<OpinionCardProps> = ({
    name,
    review,
    rating,
    time
}) => {
    const headertext = 'Poznaj opinie\nnaszych kursantów';
    return <OpinionCardShadow>
        <OpinionCardBox>
            <RatingAndTimeHeader>
                <RatingStars> <StarRaiting size={15} raiting={rating} /></RatingStars>
                <TimeLabel>{time}</TimeLabel>
            </RatingAndTimeHeader>
            <ReviewContent>{review}</ReviewContent>
            <AuthorName>- {name}</AuthorName>

        </OpinionCardBox>
    </OpinionCardShadow>;
};

export default OpinionCard;

