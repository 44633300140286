import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../routing/routes';
import { LargeMenuBox, LargeMenuInsideBox, LargeMenuLogoCircle, LargeMenuLogoContainer, LargeMenuNavLink, MockIcon, MockLink } from './style';
import logo from '../../images/logoCircle.png';
import { useMenuSize } from '../../hooks/useMenuSize';

const LargeMenu: React.FC = () => {
    const refLargeMenuBox = useRef<HTMLDivElement>(null);
    const refLargeMenuLogoContainer = useRef<HTMLDivElement>(null);
    const refLargeMenuLogoCircle = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const size = useMenuSize();

    useEffect(() => {
        const menuBox = refLargeMenuBox.current;
        const logoContainer = refLargeMenuLogoContainer.current;
        const logoCircle = refLargeMenuLogoCircle.current;

        if (menuBox && logoContainer && logoCircle) {
            menuBox.style.height = `${size.menu}px`;
            logoContainer.style.width = `${size.logo}px`;

            logoCircle.style.height = `${size.logo}px`;
            logoCircle.style.width = `${size.logo}px`;
            // logoCircle.style.borderWidth = `${size.logo / 25}px`;
        }
    }, [size.logo, size.menu]);

    return <LargeMenuBox ref={refLargeMenuBox}>
        <LargeMenuInsideBox>
            <LargeMenuNavLink to={AppRoutes.Home}>{t('menu.home')}</LargeMenuNavLink>
            <LargeMenuNavLink to={AppRoutes.Offer}>{t('menu.offer')}</LargeMenuNavLink>
            <LargeMenuNavLink to={AppRoutes.Opinions}>{t('menu.opinions')}</LargeMenuNavLink>
            <LargeMenuLogoContainer ref={refLargeMenuLogoContainer}>
                <LargeMenuLogoCircle ref={refLargeMenuLogoCircle}>
                    <MockIcon src={logo} alt="Logo wykorkowani" title="Logo wykorkowani" />
                </LargeMenuLogoCircle>
            </LargeMenuLogoContainer>
            <LargeMenuNavLink to={AppRoutes.Training}>{t('menu.training')}</LargeMenuNavLink>
            <LargeMenuNavLink to={AppRoutes.Pracing}>{t('menu.pricing')}</LargeMenuNavLink>
            <LargeMenuNavLink to={AppRoutes.Contact}>{t('menu.contact')}</LargeMenuNavLink>
        </LargeMenuInsideBox>
    </LargeMenuBox>;
};

export default LargeMenu;
