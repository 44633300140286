import styled from "styled-components";

export const HomePageContainer = styled.div`
    /* min-height: 100vh; */
    padding-top: 5px;

`;

export const MockView = styled.div`
    height: 100vh;
`;


