import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../routing/routes';
import { ButtonBox, Header, HeaderDivider, MainButton, OptionBox, OptionLabel, OptionMark, TileBox, TileDesc, TileDescBox, TileDescWithOptions, TileHeader, TileHeaderBox, TilesCenterBox, TilesContainer, YourFutureSection } from './style';

interface ITileProps {
    header: string | JSX.Element;
    desc: string | JSX.Element;
    buttonLabel: string;
    onClick: () => void;
}

const Tile: React.FC<ITileProps> = ({ header, desc, buttonLabel, onClick }) => {
    return (
        <TileBox>
            <TileHeaderBox>
                {header}
            </TileHeaderBox>
            <TileDescBox>
                {desc}
            </TileDescBox>
            <ButtonBox>
                <MainButton label={buttonLabel} variant="orange" onClick={onClick} />
            </ButtonBox>
        </TileBox>
    );
};

const YourFuture: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    return (
        <YourFutureSection>
            <HeaderDivider>
                <Header>
                    {t('yourFutureSection.header')}
                </Header>
            </HeaderDivider>
            <TilesContainer>
                <TilesCenterBox>
                    <Tile
                        header={<TileHeader>{t('yourFutureSection.tile1.header')}</TileHeader>}
                        desc={<TileDesc>{t('yourFutureSection.tile1.desc')}</TileDesc>}
                        buttonLabel={t('yourFutureSection.tile1.button')}
                        onClick={() => {
                            nav(`/${AppRoutes.Offer}`);
                        }}
                    />
                    <Tile
                        header={<TileHeader>{t('yourFutureSection.tile2.header')}</TileHeader>}
                        desc={<TileDesc>{t('yourFutureSection.tile2.desc')}</TileDesc>}
                        buttonLabel={t('yourFutureSection.tile2.button')}
                        onClick={() => {
                            nav(`/${AppRoutes.Pracing}`);
                        }}
                    />
                    <Tile
                        header={<TileHeader>{t('yourFutureSection.tile3.header')}</TileHeader>}
                        desc={<>
                            <TileDesc>{t('yourFutureSection.tile3.desc')}</TileDesc>
                            <OptionBox>
                                <OptionMark src="ptaszek.png" alt="Ikona fajki" />
                                <OptionLabel>{t('yourFutureSection.tile3.option1')}</OptionLabel>
                            </OptionBox>
                            <OptionBox>
                                <OptionMark src="ptaszek.png" alt="Ikona fajki" />
                                <OptionLabel>{t('yourFutureSection.tile3.option2')}</OptionLabel>
                            </OptionBox>
                            <OptionBox>
                                <OptionMark src="ptaszek.png" alt="Ikona fajki" />
                                <OptionLabel>{t('yourFutureSection.tile3.option3')}</OptionLabel>
                            </OptionBox>
                        </>}
                        buttonLabel={t('yourFutureSection.tile3.button')}
                        onClick={() => {
                            nav(`/${AppRoutes.Contact}`);
                        }}
                    />
                </TilesCenterBox>
            </TilesContainer>
        </YourFutureSection>
    );
};

export default YourFuture;