import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../routing/routes';
import {
    SmallMenuContainer,
    BurgerButtonBox,
    MobileLogo,
    MobileCompanyName,
    Menu,
    TopLine,
    MidLine,
    BottomLine,
    SmallMenuNavLinksBox,
    SmallMenuNavLink,
    MenuContentContainer,
    SmallMenuInfoLabelsBox,
    SmallInfoLabelBox,
    SmallInfoIcon,
    SmallInfoLabel
} from './style';
import phoneIcon from '../../images/mobile-phone-call.png';
import emailIcon from '../../images/mobile-envelope.png';
import timeIcon from '../../images/mobile-time.png';

interface IBurgerButtonProps {
    onClick: () => void;
    menuIsVisibleMode: boolean;
}

const BurgerButton: React.FC<IBurgerButtonProps> = ({ onClick, menuIsVisibleMode }) => {

    return (
        <BurgerButtonBox onClick={onClick}>
            <TopLine menuIsVisibleMode={menuIsVisibleMode} />
            <MidLine menuIsVisibleMode={menuIsVisibleMode} />
            <BottomLine menuIsVisibleMode={menuIsVisibleMode} />
        </BurgerButtonBox>
    );
};

BurgerButton.displayName = 'BurgerButton';

const SmallMenu: React.FC = () => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (menuRef.current) {
            menuRef.current.style.width = isVisible ? '100%' : '0px';
        }
    }, [isVisible]);



    return (
        <>
            <Menu ref={menuRef}>
                <MenuContentContainer>
                    <SmallMenuNavLinksBox>
                        <SmallMenuNavLink
                            to={AppRoutes.Home}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.home')}
                        </SmallMenuNavLink>
                        <SmallMenuNavLink
                            to={AppRoutes.Offer}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.offer')}
                        </SmallMenuNavLink>
                        <SmallMenuNavLink
                            to={AppRoutes.Opinions}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.opinions')}
                        </SmallMenuNavLink>
                        <SmallMenuNavLink
                            to={AppRoutes.Training}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.training')}
                        </SmallMenuNavLink>
                        <SmallMenuNavLink
                            to={AppRoutes.Pracing}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.pricing')}
                        </SmallMenuNavLink>
                        <SmallMenuNavLink
                            to={AppRoutes.Contact}
                            onClick={() => setIsVisible(!isVisible)}>
                            {t('menu.contact')}
                        </SmallMenuNavLink>
                    </SmallMenuNavLinksBox>

                    <SmallMenuInfoLabelsBox>
                        <SmallInfoLabelBox>
                            <SmallInfoIcon src={timeIcon} alt="Ikona zegara" />
                            <SmallInfoLabel>{t('contact.opening_hours')}</SmallInfoLabel>
                        </SmallInfoLabelBox>

                        <SmallInfoLabelBox>
                            <SmallInfoIcon src={emailIcon} alt="Ikona poczty" />
                            <SmallInfoLabel>{t('contact.email')}</SmallInfoLabel>
                        </SmallInfoLabelBox>

                        <SmallInfoLabelBox>
                            <SmallInfoIcon src={phoneIcon} alt="Ikona telefonu" />
                            <SmallInfoLabel>{t('contact.phone_number')}</SmallInfoLabel>
                        </SmallInfoLabelBox>
                    </SmallMenuInfoLabelsBox>
                </MenuContentContainer>

            </Menu>
            <SmallMenuContainer>
                <MobileLogo src="mobile-logo.png" alt="Logo Wykorkowani na urządzeniach mobilnych" />
                <MobileCompanyName>Wykorkowani</MobileCompanyName>
                <BurgerButton menuIsVisibleMode={isVisible} onClick={() => setIsVisible(!isVisible)} />
            </SmallMenuContainer>
        </>
    );
};

export default SmallMenu;