import styled from "styled-components";


export const ReviewsContainer = styled.div`
  min-height: calc(100vh - 110px);
  box-sizing: border-box;
  display: flex;
  padding: 5%;
  flex-direction: column;
  background-color: #f5f5f5;

`;

export const ViewHeader = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.color.training.header};
  font-size: 60px;
  font-family: 'Poppins';
  box-sizing: border-box;
  white-space: pre-line;
`;

export const OpinionCardsBox = styled.div`
  margin-top: 4em;
   display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: center;
`;