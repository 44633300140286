import React from 'react';
import { StyledInput, InputBox, ErrorMsg } from './style';

const ContactInput: React.FC<any> = ({ placeholder, name, style, register, error }) => {

    return (<InputBox style={style} isError={!!error}>
        <StyledInput placeholder={placeholder} {...register} />
        {error?.message && <ErrorMsg>{`* ${error?.message}`}</ErrorMsg>}
    </InputBox>);
};

export default ContactInput;