import styled from "styled-components";

export const InfoContainer = styled.div<any>`
display: flex;
width: 100%;
/* min-height: 80px; */
height: 100%;
margin-top: 1em;
box-sizing: border-box;
flex-direction: column;
padding: 1em;
justify-content: space-between;
position: relative;

background-color: ${({ theme }) => theme.color.pricingView.moreInfo.bgColor};
color: ${({ theme }) => theme.color.pricingView.moreInfo.color};
white-space: pre-line;
`;

export const HeaderText = styled.p`
    /* font-weight: bold; */
    text-align: center;
    font-size: 20px;
    padding: 0 2em;

`;
export const DescText = styled.p`
    padding: 1em;
    font-size: 14px;
`;
export const FooterText = styled.p`
    font-style: italic;
    font-size: 12px;
`;

export const InfoIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
`;