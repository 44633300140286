import React from 'react';
import { Outlet } from 'react-router-dom';
import ContactHeader from '../../components/ContactHeader';
import LargeFooter from '../../components/LargeFooter';
import LargeMenu from '../../components/LargeMenu';
import SmallFooter from '../../components/SmallFooter';
import SmallMenu from '../../components/SmallMenu';
import { ContentContainer } from './style';

const MainView: React.FC = () => {

    return <div>
        <ContactHeader />
        <SmallMenu />
        <LargeMenu />
        <ContentContainer>
            <Outlet />
        </ContentContainer>
        <LargeFooter />
        <SmallFooter />
    </div>;
};

export default MainView;

