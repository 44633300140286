import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { ContactHeader, Emailinput, FormBox, FormContactBox, FormHeader, HeaderAndDescBox, LabelWithIcon, LeaveLabel, SubmitButton, MoreInfoBox, MoreInfoField, MoreInfoIcon, MoreInfoLabel, NameInput, PhoneImput, PrefferedHoursBox, PrefferedHoursIcon, PrefferedHoursInput, PrefferedHoursLabel, InputsContainer, MoreInfoFieldContainer } from './style';
import UserIcon from '../../../../images/user-shape.png';
import TimeIcon from '../../../../images/time.png';



const ContactForm: React.FC = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => {
        emailjs.send('service_wykorkowani', 't_basic', {
            email: data.email,
            fullName: data.fullName,
            moreInfo: data.moreInfo,
            phone: data.phone,
            prefferedHours: data.prefferedHours,
        }).then(function () {
            window.alert('Wiadomość została pomyślnie wysłana.');
        }, function () {
            window.alert('Coś poszło nie tak. Prosimy sprobować później.');
        });


    };

    return (
        <FormContactBox>
            <HeaderAndDescBox>
                <ContactHeader>{t('contactView.form.header')}</ContactHeader>
                <LeaveLabel>{t('contactView.form.leaveContact')}</LeaveLabel>
            </HeaderAndDescBox>
            <FormBox onSubmit={handleSubmit(onSubmit)}>
                <FormHeader>{t('contactView.form.formHeader')}</FormHeader>
                <InputsContainer>
                    <NameInput
                        name={t('contactView.form.placeholders.nameAndSurname')}
                        error={errors.fullName}
                        placeholder={t('contactView.form.placeholders.nameAndSurname')}
                        register={register("fullName", { required: "Imię i nazwisko są wymagane" })} />
                    <Emailinput
                        name={t('contactView.form.placeholders.email')}
                        error={errors.email}
                        placeholder={t('contactView.form.placeholders.email')}
                        register={register("email", { required: "Adres email jest wymagany" })} />
                    <PhoneImput
                        name={t('contactView.form.placeholders.phone')}
                        error={errors.phone}
                        placeholder={t('contactView.form.placeholders.phone')}
                        register={register("phone", { required: "Numer telefonu jest wymagany" })} />
                </InputsContainer>



                <PrefferedHoursBox>
                    <LabelWithIcon>
                        <PrefferedHoursIcon src={TimeIcon} alt="Ikona zegara" />
                        <PrefferedHoursLabel>{t('contactView.form.prefferedHours')}</PrefferedHoursLabel>
                    </LabelWithIcon>

                    <PrefferedHoursInput
                        name={t('contactView.form.placeholders.time')}
                        error={errors.prefferedHours}
                        placeholder={t('contactView.form.placeholders.time')}
                        register={register("prefferedHours")}
                    />

                </PrefferedHoursBox>
                <MoreInfoBox>
                    <LabelWithIcon>
                        <MoreInfoIcon src={UserIcon} alt="Ikona ludzika" />
                        <MoreInfoLabel>{t('contactView.form.moreInfo')}</MoreInfoLabel>
                    </LabelWithIcon>
                    <MoreInfoFieldContainer>
                        <MoreInfoField
                            name={t('contactView.form.placeholders.info')}
                            error={errors.moreInfo}
                            register={register("moreInfo")}
                            placeholder={t('contactView.form.placeholders.info')}
                        />

                    </MoreInfoFieldContainer>

                </MoreInfoBox>
                <SubmitButton label={t('contactView.form.sendButton')} variant="primary" />
            </FormBox>


        </FormContactBox>
    );
};

export default ContactForm;