import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OpinionSlider from '../../../../components/OpinionSlider';
import { AppRoutes } from '../../../../routing/routes';
import { MainButton, MainButtonBox, OpinionsHeader, OpinionsSectionBox } from './style';

const OpinionsSection: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    return <OpinionsSectionBox>
        <OpinionsHeader>Co mówią o nas inni</OpinionsHeader>
        <OpinionSlider />
        <MainButtonBox>
            <MainButton label="Poznaj wszystkie opinie" variant="primary" onClick={() => {
                nav(`/${AppRoutes.Opinions}`);
            }} />
        </MainButtonBox>
    </OpinionsSectionBox>;
};

export default OpinionsSection;

