import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SmallMenuContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.color.menu.background};
  display: none;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  
  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    display: flex;
  }`}
`;

export const BurgerButtonBox = styled.div<any>`
  display: flex;
  width: 50px;
  height: 40px;
  justify-content: space-between;
  position: absolute;
  flex-direction: column;

  right: 20px;
  top: 20px;

  z-index: 2;
`;

export const BurgerButtonLine = styled.span`
  width: 100%;
  height: 6px;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
`;

export const TopLine = styled<any>(BurgerButtonLine)`
${({ menuIsVisibleMode }) => `
  background-color: ${menuIsVisibleMode ? 'black' : 'white'};
  transform: translateY(${menuIsVisibleMode ? '17px' : '0'}) rotate(${menuIsVisibleMode ? '45deg' : '0deg'});
`}
`;

export const MidLine = styled<any>(BurgerButtonLine)` 
${({ menuIsVisibleMode }) => `
  opacity: ${menuIsVisibleMode ? 0 : 1};
`}
`;

export const BottomLine = styled<any>(BurgerButtonLine)`
${({ menuIsVisibleMode }) => `  
  background-color: ${menuIsVisibleMode ? 'black' : 'white'};
  transform: translateY(${menuIsVisibleMode ? '-17px' : '0'}) rotate(${menuIsVisibleMode ? '-45deg' : '0deg'}) ;
`}
`;



export const MobileLogo = styled.img`
  height: 100%;
  margin-left: 5px;
`;

export const MobileCompanyName = styled.h2`
 color: #fff;
 font-weight: 600;
`;


export const Menu = styled.div`
  position: absolute;
  height: 100vh;
  min-height: 600px;
  width: 0px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1;
  display: flex;
  right: 0px;
  transition: width 0.3s;
  overflow: hidden;

  justify-content: center;
`;

export const MenuContentContainer = styled.div`
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 4em;
  padding-bottom: 6em;
`;

export const SmallMenuNavLinksBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SmallMenuInfoLabelsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 240px;
`;

export const SmallMenuNavLink = styled<any>(NavLink)`
  color: ${({ theme }) => theme.color.black};
  font-size: 24px;
  font-weight: 400;
  font-family: 'Poppins';
  text-decoration: none;
  padding: 0.5em;
  text-align: center;
  white-space: nowrap;

  &.active {
    color: ${({ theme }) => theme.color.menu.labelActive};
    font-weight: 600;
  }

  &.visited {
    color: ${({ theme }) => theme.color.black};
  }


`;

export const SmallInfoLabelBox = styled.div`
display: flex;
align-items: center;
margin-top: 0.5em;
`;

export const SmallInfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

export const SmallInfoLabel = styled.p`
font-size: 14px;
`;
