import React from 'react';
import { StyledTextarea, TextareaBox } from './style';

const ContactTextarea: React.FC<any> = ({ placeholder, style, register }) => {

    return (<TextareaBox style={style} >
        <StyledTextarea placeholder={placeholder} rows={3} {...register} />
    </TextareaBox>);
};

export default ContactTextarea;