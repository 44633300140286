import React, { useEffect, useState } from 'react';

export const useMenuSize = () => {
    const maxSizeOfLogo = 140;
    const minSizeOfLogo = 50;
    const minSizeOdMenu = 50;
    const maxSizeOfMenu = 80;

    const [size, setSize] = useState({
        logo: maxSizeOfLogo,
        menu: maxSizeOfMenu
    });

    useEffect(() => {
        const listener = () => {
            const winScroll =
                document.body.scrollTop || document.documentElement.scrollTop;

            const height =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight;

            const scrolled = (winScroll / height) * 100; // scroll position (%);
            const logoSize = maxSizeOfLogo - (scrolled * 6);
            const menuSize = maxSizeOfMenu - (scrolled * 2);

            if (logoSize >= minSizeOfLogo) {
                setSize({
                    logo: logoSize,
                    menu: menuSize
                });
                return;
            }

            if (scrolled > 15) {
                setSize({
                    logo: minSizeOfLogo,
                    menu: minSizeOdMenu
                });
            }


        };
        document.addEventListener('scroll', listener);
        () => {
            document.removeEventListener('scroll', listener);
        };
    }, [maxSizeOfLogo, minSizeOfLogo]);


    return size;
};