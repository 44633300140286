import React from 'react';
import { Variant } from '../../styles/buttonsTheme';
import { MainButton } from './style';

interface IButtonProps {
    label: string;
    variant: Variant;
    style?: React.CSSProperties;
    onClick: () => void;
}

const Button: React.FC<IButtonProps> = ({
    label = '',
    variant = 'primary',
    style,
    onClick,
}) => {
    return (
        <MainButton variant={variant} onClick={onClick} style={style} data-testid="Button" type="submit">
            {label}
        </MainButton>
    );
};
export default Button;
