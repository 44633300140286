import React, { useEffect } from 'react';

const WrongPageView: React.FC = () => {

    useEffect(() => {
        fetch('https://www.google.com/maps/preview/review/listentitiesreviews?authuser=0&hl=pl&gl=pl&pb=!1m2!1y2420909575115123573!2y2676582026946455336!2m1!2i10!3e1!4m5!3b1!4b1!5b1!6b1!7b1!5m2!1sl59AY5a8J8iawPAP9uy6sAI!7e81')
            .then(res => res.text())
            .then(res => {
                const op = JSON.parse(res.substring(5))[2] as any[];
                const opinions = op.map(el => ({
                    name: el[0][1],
                    review: el[3],
                    rating: el[4],
                    time: el[1]
                }));

                console.log(JSON.stringify(opinions));
            });
    }, []);


    return <div>
        <p>Ops! Something wrong.</p>
    </div>;
};

export default WrongPageView;

