import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../routing/routes';
import {
    CompanyNameHeader,
    CompanyNameHeaderBox,
    CompanyNameHeaderIcon,
    SmallFooterContainer,
    SmallFooterContentBox,
    SmallFooterInfoHeader,
    SmallFooterInfoIcon,
    SmallFooterInfoLabel,
    SmallFooterInfoPart,
    SmallFooterInfoWithIconBox,
    SmallFooterListOfCompanyInfo,
    SmallFooterNavLink,
    SmallFooterNavLinksBox,
    SocialMediaIcon
} from './style';
import phoneIcon from '../../images/mobile-phone-call.png';
import emailIcon from '../../images/mobile-envelope.png';
import timeIcon from '../../images/mobile-time.png';
import facebookIcon from '../../images/facebook-icon-blue.png';
import instagramIcon from '../../images/instagram-icon-blue.png';
import { handleClickFacebook, handleClickInstagram } from '../../helpers/socialMediaLinks';



const SmallFooter: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    return (<SmallFooterContainer>
        <SmallFooterContentBox>
            <CompanyNameHeaderBox>
                <CompanyNameHeader>Wykorkowani</CompanyNameHeader>
                <CompanyNameHeaderIcon src="korek.png" alt="Ikona korka" />
            </CompanyNameHeaderBox>
            <SmallFooterInfoPart>
                <SmallFooterInfoHeader>{t('label.company_data')}</SmallFooterInfoHeader>
                <SmallFooterListOfCompanyInfo>
                    <li>{t('company.name')}</li>
                    <li>{t('company.street')}</li>
                    <li>{`${t('company.city')}, ${t('company.zip')}`}</li>
                    <li>{`NIP ${t('company.nip')}`}</li>
                </SmallFooterListOfCompanyInfo>
            </SmallFooterInfoPart>

            <SmallFooterInfoPart>
                <SmallFooterInfoHeader>{t('label.opening_hours')}</SmallFooterInfoHeader>
                <SmallFooterInfoWithIconBox>
                    <SmallFooterInfoIcon src={timeIcon} alt="Ikona zegara" />
                    <SmallFooterInfoLabel>{t('contact.opening_hours')}</SmallFooterInfoLabel>
                </SmallFooterInfoWithIconBox>
            </SmallFooterInfoPart>

            <SmallFooterInfoPart>
                <SmallFooterInfoHeader>{t('label.call')}</SmallFooterInfoHeader>
                <SmallFooterInfoWithIconBox>
                    <SmallFooterInfoIcon src={phoneIcon} alt="Ikona telefonu" />
                    <SmallFooterInfoLabel>{t('contact.phone_number')}</SmallFooterInfoLabel>
                </SmallFooterInfoWithIconBox>
            </SmallFooterInfoPart>

            <SmallFooterInfoPart>
                <SmallFooterInfoHeader>{t('label.write_to_us')}</SmallFooterInfoHeader>
                <SmallFooterInfoWithIconBox>
                    <SmallFooterInfoIcon src={emailIcon} alt="Ikona poczty" />
                    <SmallFooterInfoLabel>{t('contact.email')}</SmallFooterInfoLabel>
                </SmallFooterInfoWithIconBox>
                <SocialMediaIcon src={instagramIcon} alt="Ikona Instagram"
                    onClick={handleClickInstagram} />
                <SocialMediaIcon src={facebookIcon} alt="Ikona Facebook"
                    onClick={handleClickFacebook} />
            </SmallFooterInfoPart>

            <SmallFooterNavLinksBox>
                <SmallFooterNavLink to={AppRoutes.PrivacyPolicy}>{t('label.privacy_policy')}</SmallFooterNavLink>
                <SmallFooterNavLink to={AppRoutes.Home}>{t('menu.home')}</SmallFooterNavLink>
                <SmallFooterNavLink to={AppRoutes.Offer}>{t('menu.offer')}</SmallFooterNavLink>
                <SmallFooterNavLink to={AppRoutes.Opinions}>{t('menu.opinions')}</SmallFooterNavLink>
                <SmallFooterNavLink to={AppRoutes.Pracing}>{t('menu.pricing')}</SmallFooterNavLink>
                <SmallFooterNavLink to={AppRoutes.Contact}>{t('menu.contact')}</SmallFooterNavLink>
            </SmallFooterNavLinksBox>
        </SmallFooterContentBox>
    </SmallFooterContainer>);
};

export default SmallFooter;