import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PricingInfo from '../PricingInfo';
import TableHeader from '../TableHeader';
import { DetailsContainer, FirstBox, NewPackagePriceValue, OldPackagePriceValue, OnePerWeek, OnePerWeekContainer, PricePackageLabel, PriceValue, PricinAndInfoContainer, RecommendUs, RecommendUsIcon, RecommendUsLabel, SecondBox, SystematicTableContainer, ThirdBox, TimeLabel, TwoPerWeek, TwoPerWeekContainer, WaitBox, WeeksContainer } from './style';


const SystematicTable: React.FC = () => {
    const infoElementRef = useRef(null);
    const { t } = useTranslation();

    return (
        <PricinAndInfoContainer>
            <SystematicTableContainer>
                <TableHeader refToElement={infoElementRef}>{t('pricingView.package.systematic.header')}</TableHeader>
                <WeeksContainer>
                    <OnePerWeekContainer>
                        <OnePerWeek>{t('pricingView.package.systematic.weeks.onePerWeek')}</OnePerWeek>
                        <DetailsContainer>
                            {/* <FirstBox>
                                <TimeLabel>{t('pricingView.package.systematic.pricing.firstOption.timeLabel')}</TimeLabel>
                                <PriceValue>{t('pricingView.package.systematic.pricing.firstOption.pricePerHour')}</PriceValue>
                                <PricePackageLabel>{t('pricingView.package.systematic.pricing.firstOption.packagePricelabel')}</PricePackageLabel>
                                <OldPackagePriceValue>{t('pricingView.package.systematic.pricing.firstOption.oldPackagePrice')}</OldPackagePriceValue>
                                <NewPackagePriceValue>{t('pricingView.package.systematic.pricing.firstOption.packagePrice')}</NewPackagePriceValue>
                            </FirstBox> */}
                            <SecondBox>
                                <PriceValue>{t('pricingView.package.systematic.pricing.secondOption.pricePerHour')}</PriceValue>
                                <TimeLabel>{t('pricingView.package.systematic.pricing.secondOption.timeLabel')}</TimeLabel>
                                {/* <PricePackageLabel>{t('pricingView.package.systematic.pricing.secondOption.packagePricelabel')}</PricePackageLabel> */}
                                {/* <OldPackagePriceValue>{t('pricingView.package.systematic.pricing.secondOption.oldPackagePrice')}</OldPackagePriceValue>
                                <NewPackagePriceValue>{t('pricingView.package.systematic.pricing.secondOption.packagePrice')}</NewPackagePriceValue> */}
                            </SecondBox>
                        </DetailsContainer>
                    </OnePerWeekContainer>
                    <TwoPerWeekContainer>
                        <TwoPerWeek>{t('pricingView.package.systematic.weeks.twoPerWeek')}</TwoPerWeek>
                        <ThirdBox>
                                <PriceValue>{t('pricingView.package.systematic.pricing.thirdOption.pricePerHour')}</PriceValue>
                                <TimeLabel>{t('pricingView.package.systematic.pricing.thirdOption.timeLabel')}</TimeLabel>
                            {/* <PricePackageLabel>{t('pricingView.package.systematic.pricing.thirdOption.packagePricelabel')}</PricePackageLabel> */}
                            {/* <OldPackagePriceValue>{t('pricingView.package.systematic.pricing.thirdOption.oldPackagePrice')}</OldPackagePriceValue>
                            <NewPackagePriceValue>{t('pricingView.package.systematic.pricing.thirdOption.packagePrice')}</NewPackagePriceValue> */}
                        </ThirdBox>
                    </TwoPerWeekContainer>
                </WeeksContainer>
            </SystematicTableContainer>
            <RecommendUs>
                <RecommendUsLabel>
                Poleć nas znajomemu, a oboje otrzymacie <span style={{color: '#E9872F'}}>10%</span> zniżki na pierwszy miesiąc zajeć!
                <RecommendUsIcon src="swinka-icon.png" height='auto' width="200px"/>
                </RecommendUsLabel>
            </RecommendUs>
           
                            <WaitBox>
                <h1 style={{ textAlign: 'center', margin: 0, fontSize: 40 }}>Czekamy właśnie na <span style={{ color: '#375CAD', fontWeight: 'bold' }}>Ciebie</span>!</h1>
            </WaitBox>
            <PricingInfo
                header={t('pricingView.package.systematic.moreInfo.header')}
                desc={t('pricingView.package.systematic.moreInfo.desc')}
                footer={t('pricingView.package.systematic.moreInfo.footer')}
                ref={infoElementRef} />
        </PricinAndInfoContainer>

    );
};

export default SystematicTable;