import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  padding: 0.5em 0;
  outline: none;
  border: 0;
  font-size: 16px;

  resize: none;
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;

  &::placeholder {
    font-size: 14px;
  }
`;

export const TextareaBox = styled.div`
  position: relative;
  padding: 0.3em;

  &::before {
    border: 1px solid rgba(0, 0, 0, 0.42);
    content: ' ';
    position: absolute;
    inset: 0px;
    pointer-events: none;
  }

  &::after {
    border-top: 2px solid rgb(25, 118, 210);
    border-bottom: 2px solid rgb(25, 118, 210);

    content: '';
    position: absolute;
    inset: 0px;
    pointer-events: none;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &:hover {
    &::before {
      border: 2px solid rgba(0, 0, 0, 0.87);
    }
  }

  &:focus-within {
    &::after {
      transform: scaleX(1);
    }

    &::before {
      border-left: 2px solid rgb(25, 118, 210);
      border-right: 2px solid rgb(25, 118, 210);
    }
  }
`;
