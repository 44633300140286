import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../routing/routes';
import { GeneralSectionsInfo, MainButton, SectionContainer, SectionContent, SectionContentItem, SectionFooter, SectionFooterDesc, SectionFooterInfo, SectionHeader, TrainingContainer, ViewHeader } from './style';

const TrainingView: React.FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();

    return <TrainingContainer>
        <ViewHeader >{t('triningView.header')}</ViewHeader>
        <GeneralSectionsInfo dangerouslySetInnerHTML={{
            __html: t('triningView.generalInfo')
        }}></GeneralSectionsInfo>

        <SectionContainer>
            <SectionHeader>{t('triningView.section1')}</SectionHeader>
            <SectionContent>
                <SectionContentItem src="teachers.png" data-alt="Zdjęcie przedstawiające uśmiechniętą nauczycielkę ubraną w zielony golf, na tle zapisanej tablicy szkolnej." >{t('triningView.teachers')}</SectionContentItem>
                <SectionContentItem src="council.png" data-alt="Zdjęcie przedstawiające spotkanie grupy ludzi - radę pedagogiczną">{t('triningView.council')}</SectionContentItem>
                <SectionContentItem src="directors.png" data-alt="Zdjęcie przedstawiające zamyślonego dyrektora szkoły wpatrującego się w laptopa.">{t('triningView.directors')}</SectionContentItem>
            </SectionContent>
            <SectionFooter>
                <SectionFooterDesc>{t('triningView.section1Desc')}</SectionFooterDesc>
                <SectionFooterInfo>{t('triningView.section1Info')}</SectionFooterInfo>
            </SectionFooter>
        </SectionContainer>

        <MainButton label={t('triningView.button')} variant="primary" onClick={() => {
            nav(`/${AppRoutes.Contact}`);
        }} />

        <SectionContainer>
            <SectionHeader>{t('triningView.section2')}</SectionHeader>
            <SectionContent>
                <SectionContentItem
                    src="firstAid.png"
                    data-alt="Zdjęcie przedstawiające człowieka prezentującego na fantomie udzielanie pierwszej pomocy."
                >
                    {t('triningView.firstAid')}
                </SectionContentItem>
                <SectionContentItem
                    src="emergencyExam.png"
                    data-alt="Zdjęcie przedstawiające stos książek na których leży biret absolwenta."
                >
                    {t('triningView.emergencyExam')}
                </SectionContentItem>
                <SectionContentItem style={{ height: 1 }} src=""></SectionContentItem>
            </SectionContent>
            <SectionFooter>
                <SectionFooterDesc>{t('triningView.section2Desc')}</SectionFooterDesc>
                <SectionFooterInfo>{t('triningView.section2Info')}</SectionFooterInfo>
            </SectionFooter>
        </SectionContainer>
    </TrainingContainer>;
};

export default TrainingView;

