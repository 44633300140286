import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../routing/routes';
import {
    Header,
    IndividualLessonsBox,
    LessonCategory,
    LessonCategoryHeader,
    LessonExamples,
    LessonTileBox,
    OfferContent,
    OfferDivider,
    OfferSection,
    LessonTileImg,
    LessonTileName,
    GroupLessonsBox,
    CourseLessonsBox,
    MainButtonBox,
    MainButton,
    CourseHeader,
    ViewHeader,
    ViewHeaderBox,
    PreloadImg
} from './style';

enum LessonImgs {
    fizyka = 'fizyka',
    chemia = 'chemia',
    biologia = 'biologia',
    matematyka = 'matematyka',
    włoski = 'wloski',
    hiszpański = 'hiszpanski',
    chorwacki = 'chorwacki',
    angielski = 'big-ben',
}
enum LessonAlts {
    fizyka = "Atom oznaczający przedmiot szkolny - fizyka.",
    chemia = "Kolba Erlenmeyera zwana kolbą stożkową oznaczająca przedmiot szkolny - chemia.",
    biologia = "Fragment DNA oznaczający przedmiot szkolny - biologia.",
    matematyka = "Ekierka, tablica i kalkulator oznaczający przedmiot szkolny - matematyka.",
    włoski = "Obrys kraju Włochy oznaczający przedmiot szkolny - język włoski.",
    hiszpański = "Flaga Hiszpania oznaczająca przedmiot szkolny - język hiszpański.",
    chorwacki = "Flaga Chorwacji oznaczająca przedmiot szkolny - język chorwacki.",
    angielski = "Big Ben jako symbol Anglii, oznaczający przedmiot szkolny - język angielski.",
}
const LessonTile: React.FC<any> = ({ name }) => {
    const basicSrc = `lessons/${LessonImgs[name as keyof typeof LessonImgs]}.png`;
    const hoverSrc = `lessons/${LessonImgs[name as keyof typeof LessonImgs]}-kolor.png`;
    const alt = LessonAlts[name as keyof typeof LessonAlts];
    return (
        <LessonTileBox>
            <PreloadImg src={hoverSrc} alt={alt} />
            <LessonTileImg basicSrc={basicSrc} hoverSrc={hoverSrc} data-alt={alt} />
            <LessonTileName>{name}</LessonTileName>
        </LessonTileBox>
    );

};

export interface IOfferProps {
    asView?: boolean;
}

const Offer: React.FC<IOfferProps> = ({
    asView = false
}) => {
    // 'włoski', 'hiszpański', 'chorwacki', 'angielski'
    const indywidualLessons = ['fizyka', 'chemia', 'biologia', 'matematyka'];
    const groupLessons = ['matematyka']; //'biologia'
    const courseLessons = ['matematyka'];

    const { t } = useTranslation();
    const nav = useNavigate();

    const renderIndywidualLessonTiles = useMemo(() => {
        return indywidualLessons.map(name => {
            return <LessonTile key={name} name={name} />;
        });

    }, [indywidualLessons]);

    const renderGroupLessonTiles = useMemo(() => {
        return groupLessons.map(name => {
            return <LessonTile key={name} name={name} />;
        });

    }, [groupLessons]);

    const renderCourseLessonTiles = useMemo(() => {
        return courseLessons.map(name => {
            return <LessonTile key={name} name={name} />;
        });

    }, [courseLessons]);


    return (
        <OfferSection asView={asView}>
            {asView
                ? <ViewHeaderBox asView={asView}>
                    <ViewHeader asView={asView}>{t('offerSection.viewHeader')}</ViewHeader>
                </ViewHeaderBox>
                : <OfferDivider>
                    <Header>{t('offerSection.header')}</Header>
                </OfferDivider>
            }
            <OfferContent asView={asView}>
                <IndividualLessonsBox>
                    <LessonCategory>
                        <LessonCategoryHeader>{t('offerSection.individualLessonHeader')}</LessonCategoryHeader>
                    </LessonCategory>
                    <LessonExamples>
                        {renderIndywidualLessonTiles}
                    </LessonExamples>
                </IndividualLessonsBox>


                <GroupLessonsBox>
                    <LessonCategory>
                        <LessonCategoryHeader>{t('offerSection.groupLessonHeader')}</LessonCategoryHeader>
                    </LessonCategory>
                    <LessonExamples>
                        {renderGroupLessonTiles}
                    </LessonExamples>
                </GroupLessonsBox>

                <CourseLessonsBox>
                    <LessonCategory>
                        <CourseHeader>{t('offerSection.courseLessonHeader')}</CourseHeader>
                    </LessonCategory>
                    <LessonExamples>
                        {renderCourseLessonTiles}
                    </LessonExamples>
                </CourseLessonsBox>
                <MainButtonBox>
                    <MainButton label={t('offerSection.priceButton')} variant="primary" onClick={() => {
                        nav(`/${AppRoutes.Pracing}`);
                    }} />
                </MainButtonBox>
            </OfferContent>
        </OfferSection>
    );
};

export default Offer;