import styled from 'styled-components';

export const SystematicTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  min-width: 370px;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    min-width: 350px;
    height: 100%;
    max-height: 380px;
  }`}

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.mobile} {
    min-width: 330px;
    height: 100%;
    max-height: 380px;
  }`}
`;

export const WeeksContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const OnePerWeekContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 5px;
`;

export const TwoPerWeekContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const OnePerWeek = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  min-height: 50px;
  max-height: 50px;
  font-weight: bold;
  font-size: 20px;
  background-color: ${({ theme }) => theme.color.pricingView.package.timeOptionBgColor};
  color: ${({ theme }) => theme.color.pricingView.package.timeOptionLabel};
`;

export const TwoPerWeek = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  min-height: 50px;
  max-height: 50px;
  font-weight: bold;
  font-size: 20px;
  background-color: ${({ theme }) => theme.color.pricingView.package.timeOptionBgColor};
  color: ${({ theme }) => theme.color.pricingView.package.timeOptionLabel};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const FirstBox = styled.div`
  flex: 1;
  text-align: center;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${({ theme }) => theme.color.pricingView.package.firstPriceOptionBgColor};
  transition: transform 0.3s;
  min-height: 190px;
  max-height: 190px;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.03);
  }
`;

export const SecondBox = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${({ theme }) => theme.color.pricingView.package.secondPriceOptionBgColor};
  transition: transform 0.3s;
  min-height: 190px;
  max-height: 190px;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.03);
  }
`;

export const ThirdBox = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${({ theme }) => theme.color.pricingView.package.thirdPriceOptionBgColor};
  transition: transform 0.3s;
  min-height: 190px;
  max-height: 190px;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.03);
  }
`;

export const TimeLabel = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.color.pricingView.package.timeLabel};
  margin-bottom: 2em;
`;

export const PriceValue = styled.h1`
  /* margin: 0;
  margin-bottom: auto; */
  margin: auto;
  font-weight: 600;
  color: ${({ theme }) => theme.color.pricingView.package.pricePerHourLabel};
`;

export const PricePackageLabel = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.color.pricingView.package.packagePriceLabel};
`;

export const OldPackagePriceValue = styled.h2`
  margin: 0;
  margin-top: 0.5em;
  font-weight: 600;
  position: relative;
  color: ${({ theme }) => theme.color.pricingView.package.oldPackagePrice};

  &:after {
    content: '';
    background-image: url(linia.png);
    background-size: contain;
    background-repeat: no-repeat;
    left: 15%;
    right: 20%;
    top: 35%;
    position: absolute;
    height: 100%;
  }
`;

export const NewPackagePriceValue = styled.h1`
  margin: 0;
  margin-top: 0.2em;
  color: ${({ theme }) => theme.color.pricingView.package.packagePrice};

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.mobile} {
    font-size: 26px;
  }`}
`;

export const PricinAndInfoContainer = styled.div``;

export const RecommendUs = styled.div`
  background-color: #DAE1FF;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  box-sizing: border-box;
  margin-top: 3em;
`;

export const RecommendUsLabel = styled.p`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  max-width: 600px;
  line-height: 1.5em;
  padding-left: 2em;
  font-family: 'Poppins';
`

export const RecommendUsIcon = styled.img`
  position: absolute;
  left: -60px;
  bottom: -40px;
`

export const WaitBox = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
