import styled from 'styled-components';
import Button from '../../../../components/Button';

export const YourFutureSection = styled.section`
  padding: 50px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    padding-top: 20px;
  }`}
`;

export const HeaderDivider = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.color.yourFutureSection.headerBoxBg};
  flex: 1;
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.yourFutureSection.header};
  white-space: pre-line;
  font-size: 60px;
  margin: 0.5em;

  ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    font-size: 50px;
  }`}
`;

export const TilesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 2;
  align-items: center;
  margin: 20px 0;
`;

export const TilesCenterBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1500px;
  flex-wrap: wrap;
`;

export const TileBox = styled.div`
  background-color: ${({ theme }) => theme.color.yourFutureSection.tileBg};
  max-width: 350px;
  min-width: 280px;
  width: 100%;

  padding: 1em;
  margin: 1em;
  min-height: 300px;
  max-height: 350px;

  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const TileHeaderBox = styled.div`
  text-align: center;
`;

export const TileHeader = styled.h3`
  font-size: 30px;
  margin: 0px;
  color: ${({ theme }) => theme.color.yourFutureSection.tileHeader};
  white-space: pre-line;
`;

export const TileDesc = styled.p`
  color: ${({ theme }) => theme.color.yourFutureSection.tileDesc};
  margin-bottom: 0.5em;
  font-weight: 600;
  white-space: pre-line;
  line-height: 1.5em;
`;

export const TileDescBox = styled.div`
  flex: 1;
  margin: 1em 0;
  text-align: center;
`;

export const TileDescWithOptions = styled.div`
  flex: 1;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonBox = styled.div`
  justify-content: center;
  display: flex;
`;

export const OptionBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em 1em;
`;

export const OptionMark = styled.img`
  height: 15px;
  width: 15px;
`;

export const OptionLabel = styled.p`
  margin-left: 0.5em;
  font-size: 14px;
`;

export const MainButton = styled(Button).attrs({
  style: {
    fontWeight: '600',
    width: 190
  }
})``;
