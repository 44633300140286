import React, { useEffect, useRef, useState } from 'react';
import OpinionCard from '../OpinionCard';
import { ButtonContainer, ItemContainer, NextButton, OpinionSliderBox, OpinionSliderItems, OpinionSliderItemsContainer, PrevButton } from './style';
import prev from '../../images/arrow-left.png';
import next from '../../images/arrow-right.png';

const OpinionSlider: React.FC = () => {
    const [opinions, setOpinions] = useState<any>([]);
    const sliderRef = useRef<HTMLDivElement>(null);
    const [buttonVisible, setButtonVisible] = useState({
        prev: false,
        next: true
    });

    useEffect(() => {
        fetch(`/opinions.json?t=${new Date().getTime()}`)
            .then((res) => res.json())
            .then((data) => {
                setOpinions(data.reverse());
            });
    }, []);

    const handleMoveClick = (prev: boolean) => {
        const slider = sliderRef.current;

        if (!slider) {
            return;
        }
        const left = slider.style.left === ''
            ? 0
            : +slider.style.left.substring(0, slider.style.left.length - 2);

        if (prev) {
            slider.style.left = `${left + 360}px`;
        } else {
            slider.style.left = `-${Math.abs(left) + 360}px`;
        }


        setButtonVisible({ next: true, prev: true });
        if (slider.style.left === '0px') {
            setButtonVisible(o => ({ ...o, prev: false }));
        }

        setTimeout(() => {
            const isInViewport = (element: any) => {
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            };

            if (isInViewport(sliderRef.current?.lastChild)) {
                setButtonVisible(o => ({ ...o, next: false }));
            }

        }, 200);
    };

    return <OpinionSliderBox>
        <ButtonContainer>
            {buttonVisible.prev && <PrevButton src={prev} alt="Strzałka cofnięcia" onClick={() => handleMoveClick(true)} />}
        </ButtonContainer>

        <OpinionSliderItemsContainer>
            <OpinionSliderItems ref={sliderRef}>
                {opinions.map((opinion: any) => (
                    <ItemContainer key={opinion.name + opinion.review}>
                        <OpinionCard
                            review={opinion.review}
                            rating={opinion.rating}
                            name={opinion.name}
                            time={opinion.time}
                        />
                    </ItemContainer>

                ))}
            </OpinionSliderItems>
        </OpinionSliderItemsContainer>
        <ButtonContainer>
            {buttonVisible.next && <NextButton src={next} alt="Strzałka do przodu" onClick={() => handleMoveClick(false)} />}
        </ButtonContainer>
    </OpinionSliderBox>;
};

export default OpinionSlider;

