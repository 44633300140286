import styled from "styled-components";
import { Variant } from "../../styles/buttonsTheme";

interface IButtonProps {
    variant: Variant;
}

export const MainButton = styled.button<IButtonProps>`
    height: 40px;
    color: #fff;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    outline: 0;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    outline: 0;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    ${({ variant, theme }) => theme.button[variant]}
`;