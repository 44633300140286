import styled from 'styled-components';
import Button from '../../components/Button';

export const TrainingContainer = styled.div`
  min-height: calc(100vh - 110px);
  box-sizing: border-box;
  display: flex;
  padding: 5%;
  flex-direction: column;
`;

export const ViewHeader = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.color.training.header};
  font-size: 60px;
  font-family: 'Poppins';
  box-sizing: border-box;
`;

export const ViewHeaderBox = styled.div<{ asView: boolean; }>`
  padding: ${({ asView }) => (asView ? '0px' : '1em 10vmin 0 10vmin')};
`;

export const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
`;

export const SectionHeader = styled.h2`
    align-self: flex-start;
    color: ${({ theme }) => theme.color.training.sectionHeader};
    margin: 0.5em 0;
    font-size: 35px;
`;

export const SectionContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1500px;
    justify-content: space-between;

    ${({ theme }) => `
   @media (max-width: 1190px) {
    justify-content: space-around;;
  }`}
`;

export const SectionContentItem = styled.div<{ src: string; }>`
  height: 300px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;

  color: ${({ theme }) => theme.color.training.sectionItemLabel};
  font-size: 35px;
  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;

  transition: transform 0.3s;

&:hover {
  transform: scale(1.03);
}

  ${({ theme, src }) => `
   ${src && `background: url(${src});`}
   @media ${theme.maxBreakpoints.desktop} {
    height: 200px;
    width: 350px;
  }`}

`;

export const SectionFooter = styled.div`
align-self: flex-start;
margin: 1em 0;
`;

export const SectionFooterDesc = styled.p`
color: ${({ theme }) => theme.color.training.sectionDesc};
padding: 0.5em 0;
`;

export const SectionFooterInfo = styled.p`
color: ${({ theme }) => theme.color.training.sectionDesc};
padding: 0.5em 0;
`;

export const GeneralSectionsInfo = styled.h4`
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  margin-bottom: 2em;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
`;

export const MainButton = styled(Button).attrs({
  style: {
    fontSize: 15,
    fontWeight: 600,
    width: 280,
    height: 60,
    padding: 0,
    boxSizing: 'border-box',
    alignSelf: 'center',
    margin: '1em'
  }
})``;