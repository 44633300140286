import styled from "styled-components";
import Button from "../../../../components/Button";

export const OpinionsSectionBox = styled.section`
background: linear-gradient(178deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
`;

export const OpinionsHeader = styled.h1`
    padding-top: 1em;
    padding-left: 5%;
    font-size: 40px;

    ${({ theme }) => `
   @media ${theme.maxBreakpoints.tablet} {
    text-align: center;
  }`}
`;

export const MainButtonBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
`;

export const MainButton = styled(Button).attrs({
    style: {
        margin: '2em',
        padding: '10px 60px',
        fontSize: '20px',
        height: '100%',
        minHeight: '40px',
        fontWeight: '600'
    }
})``;